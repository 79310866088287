.false_alarms_wrapper {
  border-radius: 15px;
  border: 2px;
  border-width: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-color: rgb(0, 0, 0);
  /* width: 100%; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  height: 140px;
  margin: 20px;
  overflow: hidden;
  color: whitesmoke;
  font-size: 17px;
}

.false_alarms_wrapper:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-out;
  /* background-color: rgba(41, 182, 246, 0.1); */
}

.primary_component_wrapper {
  display: flex;
  justify-content: space-around;
  font-weight: 600;
  align-items: center;
  padding-right: 50px;
  padding-left: 20px;
  height: 50%;
}

.secondary_component_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-left: 50px;
  height: 50%;
  border-top-color: white;
  border-top-style: solid;
  border-top-width: 1.5px;
  /* border: 1px solid white; */
  /* z-index: 1; */
}

.secondary_component_wrapper {
  display: flex;
  padding: 2px;
}

.metric_wrapper {
  padding: 0 5px 0 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 2px;
  /* padding: 15px */
  /* height: 70px; */
}

/* .right_metric_wrapper {
    padding: 5px;
  } */

.left_metric_wrapper_value {
  margin-bottom: 10px;
  transform: translate(5px, 0);
  font-weight: 900;
  font-size: 18px;
  width: 75px;
}

.merg_name {
  font-size: 11px;
  color: #e8f9fd;
}

.paper {
  padding: 20px;
  text-align: "center";
  border-radius: 8px;
  color: #f6f6f6;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  margin-left: 20px;
}

.dashbord_logo {
  /* width: 150px; */
  height: 100px;
  /* margin-top: 40px; */
}

.dashboard_img {
  border-radius: 25px;
  background: white;
  overflow: hidden;
  border-color: rgb(8, 8, 8);
  border: 2px;
  width: 50;
  padding: 5px;
}

/* .dashboard_img > img {

    border-radius: 30;
    background: white;
    overflow: hidden;
    border-color: lightblue;
    border: 1px;
    width: 60;
  } */

.pill_wrapper {
  /* margin-top: 10px; */
  min-width: 250px;
  max-width: 300px;
  width: 270px;
  border-radius: 10px;
  padding: 13px;
  display: flex;
  justify-content: space-between;
  color: white;
  background: linear-gradient(
    90deg,
    rgb(71, 115, 184) 0%,
    rgba(57, 72, 158, 0.98) 100%
  );
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.pill_wrapper:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease-out;
  /* background-color: rgba(41, 182, 246, 0.1); */
  cursor: pointer;
}

.pill_value {
  font-weight: 700;
  font-size: 18px;
}
.pill_name {
  font-weight: 300;
}

.activity_stats {
  /* position: relative; */
  height: 248px;
  min-width: 300px;
  background-color: white;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* overflow-x: hidden; */
  /* margin-right: 50px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

.search:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-out;
  /* background-color: rgba(41, 182, 246, 0.1); */
}

.search {
  padding-left: 15px;
  padding-right: 15px;
}

.activity_stats_header {
  top: 0;
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  padding-right: 25px;
  color: white;
  background: linear-gradient(
    90deg,
    rgb(71, 115, 184) 0%,
    rgba(57, 72, 158, 0.98) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity_stats_body {
  /* overflow-y: scroll; */
  /* z-index: 2; */
  width: 95%;
  /* height: 290px; */
  padding: 20px;
  color: grey;
  font-size: 13px;
  margin-top: 10px;
}

.activity_stats_body_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.2px solid #ccce;
  width: 100%;
}

.activity_stats_body_value {
  font-weight: 600;
}

.activity_stats_body_name {
  font-size: 12px;
}

.activity_stats_body > div:hover {
  background-color: rgb(236, 247, 255);
  cursor: pointer;
  color: #023076;
}

.mainTimeWrapper {
  display: flex;
  background-color: #f3f3f3;
  padding: 20px;
  width: 700px;
  justify-content: space-between;
  border-radius: 25px;
  padding-top: 30px;
  border: 1px solid #ccce;
}

.timeSelector {
  margin-bottom: 40px;
  color: #15325f;
}

.bold_text {
  font-family: open sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #15325f;
}
