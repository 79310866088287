.scheduleCard {
  margin-bottom: 10;
}
.heading {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  margin-top: 20;
  margin-bottom: 20;
}

.add-schedule {
  padding-left: 45px;
  margin-top: -35px;
}
