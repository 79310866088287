.item {
  display: flex;
  margin: 20px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #f6f6f6;
}
.item2 {
  max-width: 365px;
  display: flex;
  margin: 13px 5px;
  padding: 3px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #f6f6f6;
}

.item3 {
  max-width: 365px;
  display: flex;
  margin: 13px 5px;
  padding: 3px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  /* background-color: #e4e4e4; */
  background-color: rgb(226, 245, 252);
}

.alerts {
  max-width: 365px;
  display: flex;
  margin: 13px 5px;
  padding: 3px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  /* background-color: #e4e4e4; */
  background-color: rgb(227, 185, 184);
}

.icon_white {
  color: "#FFFFFF";
}

.icon_blue {
  color: "#15325F";
}

.alert_card_unreviewed {
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-size: 12px;
  color: #1f3161;
  line-height: 14px;
  overflow: hidden;
  background-color: #b4d5fa;
  margin: 3px;
}

.standard_text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: #15325f;
  font-size: 12;
}

.item2:hover {
  background-color: #ebebeb;
  /* opacity: 0.5; */
  cursor: pointer;
}

.items-wrapper {
  overflow-y: scroll;
  height: 430px;
}
.items-wrappe2 {
  overflow-y: scroll;
  height: 400px;
}

.item-wrapper3 {
  background-color: #d6d6d6;
}

.item-wrapper {
  font-size: 12px;
}

.btn {
  text-decoration: none;
  background-color: rgb(0, 60, 128);
  color: white;
  border-radius: 4px;
  padding: 0.4rem 10px;
  border: 1px solid rgb(0, 60, 128);
  cursor: pointer;
  font-weight: 700;
}

.btn:hover,
.btn:active {
  background-color: #116eac;
  border-color: #116eac;
  font-weight: 700;
}
.btn_red {
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  background-color: #f32424;
  color: white;
  border-radius: 4px;
  padding: 0.4rem 10px;
  border: 1px solid #f32424;
  box-shadow: 0 2px 20px rgb(243, 7, 7, 0.4);
  cursor: pointer;
}

.btn_red:hover,
.btn_red:active {
  opacity: 0.7;
  box-shadow: 0 2px 20px rgb(243, 7, 7, 0.4);
}
.search-btn {
  text-decoration: none;
  width: 10px;
  height: 25px;
  background-color: #f6f6f6;
  color: rgb(0, 60, 128);
  border-radius: 25px;
  padding: 0.75rem 1.5rem;
  border: 1px solid rgba(0, 60, 128, 0.296);
  border-width: 1px;
  font-weight: 700;
  cursor: pointer;
}

.search-btn:hover,
.search-btn:active {
  background-color: rgb(0, 60, 128);
  border-color: rgb(0, 60, 128);
  color: white;
}
