.ant-select-selector {
  height: 56px;
  background-color: transparent;
  background-color: #ccce;
  /* border-radius: 12px; */
}

.ant-select-single {
  /* height: 53px; */
  /* background-color: transparent; */
  background-color: white;
  /* border: 1px solid #ccce; */
  border-radius: 4px;
}

.ant-select-selection-search-input {
  padding-top: 5px;
}

.ant-select {
  /* max-height: 51px; */
  /* overflow: auto; */
  /* height: 30 */
  /* border: 1px solid rgba(87, 87, 87, 0.933); */
  /* border-radius: 12px; */
  color: #15325f;
  /* height: 56px; */
}

.ant-select-selection-placeholder {
  color: #15325f;
  font-weight: 600;
  font-size: 12px;
}
