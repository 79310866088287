hr.MuiDivider-root.right-drawer-divider-top {
  margin-top: 22px;
}

.groups-edit-top {
  display: flex;
  align-items: center;
  .groups-text {
    font-family: Open Sans;
    height: 66px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #15325f;
    margin-left: 12px;
  }
  .group-icon {
    margin-left: 30px;
  }
}
.list-groups {
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 30px;
    li {
      font-family: Open Sans;
      color: #71829e;
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}

.site-top-section {
  margin-top: 2px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  .spacer {
    flex: 1 auto;
  }
  .text {
    margin-left: 12px;
    font-family: Open Sans;
    height: 66px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #15325f;
  }
  .icon-button {
    margin-right: 25px;
  }
}

.shared-top-section {
  margin-top: 2px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  .spacer {
    flex: 1 auto;
  }
  .text {
    margin-left: 12px;
    font-family: Open Sans;
    height: 66px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #15325f;
  }
  .icon-button {
    margin-right: 25px;
  }
}
