table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  border: 1px solid #dddddd;
  /* border-right: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

th {
  font-size: 17;
  color: inherit;
}
