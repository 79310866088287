circle.true {
  background: "#F8E065";
  height: "20px";
  width: "20px";
  border-radius: "40px";
}

circle.false {
  background: "#70D4A5";
  height: "20px";
  width: "20px";
  border-radius: "40px";
}

.mobile-icon {
  padding-left: 30px;
}

.drawer-container {
  display: flex;
  .drawer-count {
    height: 15px;
    width: 75px;
    background: #377ae1;
    border-radius: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .count {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      position: relative;
      top: -1px;
    }
  }
}
