//Top Bar Styling
// Navigation Variables
$content-width: 1000px;
$breakpoint: 799px;
$nav-height: 64px;
$nav-background: #262626;
$nav-font-color: #ffffff;
$link-hover-color: #2581dc;

// Outer navigation wrapper
.navigation {
  height: $nav-height;
  background: linear-gradient(90deg, #4773b8 0%, rgba(57, 72, 158, 0.98) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.navcolor {
  background: linear-gradient(90deg, #4773b8 0%, rgba(57, 72, 158, 0.98) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
// Logo and branding
.brand {
  position: absolute;
  padding-left: 20px;
  float: left;
  line-height: $nav-height;
  text-transform: uppercase;
  font-size: 1.4em;
  a,
  a:visited {
    color: $nav-font-color;
    text-decoration: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
  }
  img.logo {
    margin-top: -13px;
  }
}
.version {
  position: absolute;
  padding-left: 20px;
  float: left;
  margin-top: 40px;
  margin-left: 80px;
  a,
  a:visited {
    color: $nav-font-color;
    text-decoration: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
  }
}
// Container with no padding for navbar
.nav-container {
  margin: 0 auto;
  .action-tab {
    position: relative;
    // float: right;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .MuiAvatar-root.MuiAvatar-circle.avatar-icon.MuiAvatar-colorDefault {
      background: rgba(0, 0, 0, 0.26);
      margin-right: 5px;
      // margin-left: 10px;
    }
    .check-background {
      background: #70d4a5;
      border-radius: 40px;
      height: 24px;
      width: 24px;
      svg.MuiSvgIcon-root.check-icon {
        fill: #fff;
        font-size: 20px;
      }
    }
    span.MuiBadge-root.badge {
      margin-right: 10px;
      .MuiBadge-anchorOriginTopRightRectangle {
        top: 5px;
        right: 6px;
        transform: scale(1) translate(50%, -50%);
        transform-origin: 100% 0%;
      }
      svg.MuiSvgIcon-root.bell-icon {
        color: #fff;
      }
    }
  }
}

// Navigation
nav {
  margin-left: 182px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      a,
      a:visited {
        display: block;
        padding: 0 20px;
        line-height: $nav-height;
        color: $nav-font-color;
        text-decoration: none;
        &:hover {
          color: $nav-font-color;
        }
        &:not(:only-child):after {
          padding-left: 4px;
          content: " ▾";
        }
      } // Dropdown list
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}

// Dropdown list binds to JS toggle event
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: $nav-height;
  width: $nav-height;
}
@media only screen and (max-width: 798px) {
  // Hamburger nav visible on mobile only
  .nav-mobile {
    display: block;
  }
  nav {
    width: 100%;
    padding: $nav-height 0 15px;
    ul {
      display: none;
      li {
        float: none;
        a {
          padding: 15px;
          line-height: 20px;
        }
        div {
          padding: 15px;
          line-height: 20px;
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
}
@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
}
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $nav-font-color;
    position: absolute;
    display: block;
    content: "";
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

// Page content
article {
  max-width: $content-width;
  margin: 0 auto;
  padding: 10px;
}

a.active {
  color: #c7d2e8 !important;
  font-weight: bold;
  -webkit-box-shadow: inset -3px -6px 0px 0px rgba(238, 70, 35, 1);
  -moz-box-shadow: inset -3px -6px 0px 0px rgba(238, 70, 35, 1);
  box-shadow: inset 0px -4px 0px 0px rgba(238, 70, 35, 1);
}

img.logo {
  width: 138px;
  height: 32px;
}

.org-menu,
.avatar-icon {
  cursor: pointer;
  color: #c7d2e8 !important;
  padding: 10px;
}
