.slideshow-container {
  position: relative;
}

.slide {
  position: relative;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #333;
}
